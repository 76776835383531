if (import.meta.env.MODE === 'development') {
    console.log('Dentro configLoader.js');
}

fetch('/api/config')
    .then(response => response.json())
    .then(data => {
        window.defaultHostingService = data.defaultHostingService;
        window.sendEmail = data.sendEmail;
        window.devTeamEmailAddress = data.devTeamEmailAddress;
        window.errorDelTempLocalFileCode = data.errorDelTempLocalFileCode;
        window.errorDelTempExtFileCode = data.errorDelTempExtFileCode;
        window.settingAttempts = data.settingAttempts;
        window.uploadStatus = data.uploadStatus;
        window.waiting = data.waiting;
        window.uploadFiniscedText = data.uploadFiniscedText;
        window.uploadAndScanText = data.uploadAndScanText;
        window.btnDel = data.btnDel;
        window.selectFiles = data.selectFiles;
        window.startingUpload = data.startingUpload;
        window.startingSaving = data.startingSaving;
        window.startingScan = data.startingScan;
        window.scanError = data.scanError;
        window.fileInfected = data.fileInfected;
        window.oneOrMoreFilesInfected = data.oneOrMoreFilesInfected;
        window.loading = data.loading;
        window.saved = data.saved;
        window.uploaded = data.uploaded;
        window.successfully = data.successfully;
        window.of = data.of;
        window.emogySad = data.emogySad;
        window.emogyHappy = data.emogyHappy;
        window.emogyAngry = data.emogyAngry;
        window.enableVirusScanning = data.enableVirusScanning;
        window.disableVirusScanning = data.disableVirusScanning;
        window.allFileIsSaved = data.allFileIsSaved;
        window.virusScanAdvise = data.virusScanAdvise;
        window.uploadError = data.uploadError;
        window.getPresignedUrlError = data.getPresignedUrlError;
        window.deleteFileError = data.deleteFileError;
        window.someError = data.someError;
        window.completeFailure = data.completeFailure;
        window.temporaryFolder = data.temporaryFolder;
        window.allowedExtensions = data.allowedExtensions;
        window.allowedMimeTypes = data.allowedMimeTypes;
        window.maxSize = data.maxSize;
        window.allowedExtensionsMessage = data.allowedExtensionsMessage;
        window.allowedMimeTypesMessage = data.allowedMimeTypesMessage;
        window.maxSizeMessage = data.maxSizeMessage;
        window.invalidFileNameMessage = data.invalidFileNameMessage;
        window.allowedExtensionsListMessage = data.allowedExtensionsListMessage;
        window.allowedMimeTypesListMessage = data.allowedMimeTypesListMessage;
        window.titleExtensionNotAllowedMessage = data.titleExtensionNotAllowedMessage;
        window.titleFileTypeNotAllowedMessage = data.titleFileTypeNotAllowedMessage;
        window.titleFileSizeExceedsMessage = data.titleFileSizeExceedsMessage;
        window.titleInvalidFileNameMessage = data.titleInvalidFileNameMessage;
        window.envMode = data.envMode;

        if (window.envMode === 'development') {
            console.log('Configurazioni caricate con successo:', data);
        }
    })
    .catch(error => console.error('Errore durante il caricamento delle configurazioni:', error));

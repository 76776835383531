if (import.meta.env.MODE === 'development') {
    console.log('Dentro errorMessage');
}

const SEND_MAIL = window.sendEmail;
if (import.meta.env.MODE === 'development') {
    console.log('SEND_MAIL', SEND_MAIL);
}


/*
* Questa fetch viene eseguita una volta al caricamento dell'app per recuperare tutti i codici di errore necessari
* e salvarli globalmente nella variabile window.errorCodes. La fetch è esterna alla funzione errorMessage
* perché è più efficiente eseguire una singola richiesta al server piuttosto che richiedere i dati ogni volta
* che viene chiamata la funzione errorMessage.
*/
fetch('/error_codes')
    .then(response => response.json())
    .then(data => {
        window.errorCodes = data;
        // if (import.meta.env.MODE === 'development') {
        //    console.log('Error codes loaded:', window.errorCodes);
        // }
    })
    .catch(error => console.error('Error loading error codes:', error));


/*
 * Questa fetch viene eseguita una volta al caricamento dell'app per recuperare tutte le traduzioni necessarie
 * e salvarle globalmente nella variabile window.translations. La fetch è esterna alla funzione errorMessage
 * perché è più efficiente eseguire una singola richiesta al server piuttosto che richiedere i dati ogni volta
 * che viene chiamata la funzione errorMessage.
 *
 * La funzione errorMessage viene definita solo dopo che la fetch ha completato il caricamento delle traduzioni.
 * In questo modo, ogni volta che si chiama errorMessage, si hanno già le traduzioni caricate in memoria,
 * evitando così richieste aggiuntive e migliorando le prestazioni dell'app.
 *
 * Questo approccio consente di avere i dati pronti globalmente e garantisce che la funzione errorMessage
 * possa accedere immediatamente alle traduzioni senza ritardi causati da ulteriori fetch.
 */
fetch('/translations')
    .then(response => response.json())
    .then(data => {
        window.translations = data;

        // if (import.meta.env.MODE === 'development') {
        //     console.log('window.translations', window.translations.label);
        // }

        // Definisci la funzione errorMessage solo dopo che le traduzioni sono state caricate
        window.errorMessage = function(codeError, params = {}) {
            let userMessage = '';
            let devMessage = '';

            if (import.meta.env.MODE === 'development') {
                console.log('File: errorMessage. ACTION: codeError', codeError);
            }

            switch (codeError) {
                case 400:
                    userMessage =  'Richiesta non valida';
                    devMessage = 'Richiesta non valida';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError: 400', codeError);
                    }

                    return { userMessage, devMessage };
                case 401:
                    userMessage =  'Non autorizzato';
                    devMessage = 'Non autorizzato';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError 401', codeError);
                    }

                    return { userMessage, devMessage };

                case 403:
                    userMessage =  'Vietato';
                    devMessage = 'Vietato';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError 403', codeError);
                    }

                    return { userMessage, devMessage };

                case 404:
                    userMessage =  'Non trovato';
                    devMessage = 'Non trovato';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError 404', codeError);
                    }

                    return { userMessage, devMessage };

                case 405:
                    userMessage =  'Metodo non consentito';
                    devMessage = 'Metodo non consentito';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError 405', codeError);
                    }

                    return { userMessage, devMessage };
                case 500:
                    userMessage =  'Errore interno del server';
                    devMessage = 'Errore interno del server';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError 500'), codeError;
                    }

                    return { userMessage, devMessage };
                case 502:
                    userMessage = 'Gateway non valido';
                    devMessage = 'Gateway non valido';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError 502', codeError);
                    }

                    return { userMessage, devMessage };

                case 503:

                    userMessage = 'Servizio non disponibile';
                    devMessage = 'Servizio non disponibile';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError 503', codeError);
                    }

                    return { userMessage, devMessage };
                case 504:

                    userMessage = 'Gateway Timeout';
                    devMessage = 'Gateway Timeout';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError 504', codeError);
                    }

                    return { userMessage, devMessage };
                case window.errorCodes['MAX_FILE_SIZE']:

                    window.maxSize = window.maxSize / 1024 / 1024;
                    userMessage = replacePlaceholder(window.maxSizeMessage, 'size', window.maxSize);
                    devMessage = 'File troppo grande';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError upload_fallito', codeError);
                    }

                    return { userMessage, devMessage };

                case window.errorCodes['INVALID_FILE_EXTENSION']:

                    userMessage = window.translations.errors['file_extension_not_valid'];
                    devMessage = 'Estensione file non valida';

                    if (import.meta.env.MODE === 'development') {
                        console.log("File: errorMessage. Action: " + devMessage, codeError);
                    }

                    return { userMessage, devMessage };

                case window.errorCodes['MIME_TYPE_NOT_ALLOWED']:

                    userMessage = window.translations.errors['mime_type_not_allowed'];
                    devMessage = 'Il tipo MIME del file non è valido';

                    if (import.meta.env.MODE === 'development') {
                        console.log("File: errorMessage. Action: " + devMessage, codeError);
                    }

                    return { userMessage, devMessage };

                case window.errorCodes['INVALID_FILE_NAME']:

                    userMessage = window.translations.errors['invalid_file_name'];
                    devMessage = 'Nome del file non valido';

                    if (import.meta.env.MODE === 'development') {
                        console.log("File: errorMessage. Action: " + devMessage, codeError);
                    }

                    return { userMessage, devMessage };

                case window.errorCodes['ERROR_GETTING_PRESIGNED_URL']:

                    userMessage = window.translations.errors['error_getting_presigned_URL_for_user'];
                    devMessage = `${SEND_MAIL} Errore durante il recupero dell\'URL prefirmato`;

                    if (import.meta.env.MODE === 'development') {
                        console.log("File: errorMessage. Action: " + devMessage, codeError);
                    }

                    return { userMessage, devMessage };

                case window.errorCodes['INVALID_IMAGE_STRUCTURE']:

                    userMessage = window.translations.errors['invalid_image_structure'];
                    devMessage = 'Struttura del file immagine corrotta';

                    if (import.meta.env.MODE === 'development') {
                        console.log("File: errorMessage. Action: " + devMessage, codeError);
                    }

                    return { userMessage, devMessage };

                case window.errorCodes['INVALID_FILE_PDF']:

                    userMessage = window.translations.errors['invalid_pdf_file'];
                    devMessage = 'File PDF non valido';

                    if (import.meta.env.MODE === 'development') {
                        console.log("File: errorMessage. Action: " + devMessage, codeError);
                    }

                    return { userMessage, devMessage };

                case window.errorCodes['ERROR_DELETING_LOCAL_TEMP_FILE']:
                    // Questo errore si verifica quando si tenta di eliminare un file temporaneo locale, ma il file non esiste.

                    // Per questo errore occorre inviare una mail al devTeam.
                    // L'errore si è prodotto nel metodo deleteTempFile() del file UploadController.php
                    devMessage = `${SEND_MAIL} : Errore durante l\'eliminazione del file temporaneo locale`;

                    if (import.meta.env.MODE === 'development') {
                        console.log("File: errorMessage.js Action:", devMessage, codeError);
                    }

                    return { devMessage };

                case window.errorCodes['ERROR_DELETING_EXT_TEMP_FILE']:
                    // Questo errore si verifica quando si tenta di eliminare un file temporaneo su un hosting esterno, ma il file non esiste.

                    // Per questo errore occorre inviare una mail al devTeam.
                    // L'errore si è prodotto nel metodo deleteTempFile() del file UploadController.php

                    devMessage = `${SEND_MAIL} : Errore durante l\'eliminazione del file temporaneo su ${window.defaultHostingService}`;

                    if (import.meta.env.MODE === 'development') {
                        console.log("File: errorMessage.js Action:", devMessage, codeError);
                    }

                    return { devMessage };

                case 'scan_failed':
                    userMessage =  window.translations.label['scan_error'];
                    devMessage = `${SEND_MAIL} Errore durante la scansione di un file`;

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError scan_failed', codeError);
                    }

                    return { userMessage, devMessage };

                case 'scan_file_not_found':
                    userMessage =  window.translations.label['scan_error'];
                    devMessage = 'Impossibile trovare il file da scansionare';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError scan_file_not_found', codeError);
                    }

                    return { userMessage, devMessage };

                case 'upload_file_non_valido':
                    userMessage =  window.translations.label['scan_error'];
                    devMessage = 'Impossibile trovare il file da scansionare';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError upload_file_non_valido', codeError);
                    }

                    return { userMessage, devMessage };

                case 'upload_file_troppo_grande':
                    userMessage =  window.translations.label['scan_error'];
                    devMessage = 'Impossibile trovare il file da scansionare';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError upload_file_troppo_grande', codeError);
                    }

                    return { userMessage, devMessage };

                case 'upload_file_non_caricato':
                    userMessage =  window.translations.label['scan_error'];
                    devMessage = 'Impossibile trovare il file da scansionare';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError upload_file_non_caricato', codeError);
                    }
                    return { userMessage, devMessage };

                default:
                    userMessage =  '';
                    devMessage = 'Errore sconosciuto';

                    if (import.meta.env.MODE === 'development') {
                        console.log('File: errorMessage. Action: codeError default', codeError);
                    }

                    return { userMessage, devMessage };
            }
        };
    })
    .catch(error => console.error('Error loading translations:', error));

    function replacePlaceholder(text, placeholder, value) {
        return text.replace(new RegExp(`:${placeholder}`, 'g'), value);
    }

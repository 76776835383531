if (import.meta.env.MODE === 'development') {
    console.log('Dentro customOnerror');
}
window.onerror = function(message, codeError = 0, stack  = null) {

    if (import.meta.env.MODE === 'development') {
        console.log('Dentro window.onerror', codeError, stack );
    }

    // Estrai la stack trace dell'errore
    /*
    | Questa riga significa che se error è definito e non è null, allora assegna la proprietà stack di error alla variabile stack. Se error è null o undefined, stack verrà impostato su undefined.
    */
    // Estrai la stack trace dell'errore
    // Estrai la stack trace dell'errore
    const errorStack = stack ? String(stack) : undefined;

    if (import.meta.env.MODE === 'development') {
        // Log per confermare cosa viene passato a extractErrorDetails
        console.log('Stack prima di chiamare extractErrorDetails:', errorStack);
    }

    // Estrai il numero di riga e colonna dalla stack trace
    const errorDetails = window.extractErrorDetails(errorStack, codeError);
    errorDetails.message = message.devMessage;
    errorDetails.stack = errorStack;

    console.log('Messaggio:', errorDetails.message);
    console.log('Metodo:', errorDetails.methodName);
    console.log('File:', errorDetails.fileName);
    console.log('Linea:', errorDetails.lineNumber);
    console.log('Colonna:', errorDetails.columnNumber);
    console.log('Codice errore:', errorDetails.codeError);

    // Invia l'errore al server
    fetch('/report-js-error', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify(errorDetails)

    }).then(response => response.json())

    .then(data => {
        if (import.meta.env.MODE === 'development') {
           console.info('Risposta dal server:', data);
        }
    }).catch(error => {
        console.error('Errore durante l\'invio della segnalazione dell\'errore:', error);
    });

    // Puoi anche decidere di far apparire l'errore sulla console del browser
    if (import.meta.env.MODE === 'development') {
        console.error('Errore catturato:', message, codeError, stack);
    }
};

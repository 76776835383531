// utils.js

// Log che indica che il file utils.js è stato caricato correttamente
if (window.envMode === 'local') {
    console.log('utils.js loaded successfully.');
}

/**
 * Estrae il numero di riga, colonna, nome del file, metodo dalla stack trace di un errore, e aggiunge il codice di errore.
 *
 * @param {string} stack - La stack trace da cui estrarre le informazioni.
 * @param {string|number} codeError - Il codice di errore associato all'errore.
 * @returns {object} Un oggetto contenente il numero di riga, colonna, nome del file, metodo e il codice di errore.
 */
window.extractErrorDetails = function (stack, codeError) {

    try {

        console.log('Stack trace:', stack);

        if (!stack) {
            console.error('Stack is undefined or null');
            return { methodName: 'N/A', fileName: 'N/A', lineNumber: 'N/A', columnNumber: 'N/A', codeError };
        }

        const stackLines = stack.split('\n');

        // Cerca la prima linea che contiene informazioni su file, linea e colonna
        for (const line of stackLines) {
            const match = line.match(/at\s+([^\s]+)\s+\((.*):(\d+):(\d+)\)/);
            if (match) {
                const methodName = match[1];
                const fileName = match[2].split('/').pop();  // Estrae solo il nome del file
                const lineNumber = match[3];
                const columnNumber = match[4];
                return { methodName, fileName, lineNumber, columnNumber, codeError };
            }
        }

        // Se non trovi nulla, ritorna N/A
        return { methodName: 'N/A', fileName: 'N/A', lineNumber: 'N/A', columnNumber: 'N/A', codeError };

    } catch (e) {

        console.error('Errore in extractErrorDetails:', e);
        return { methodName: 'N/A', fileName: 'N/A', lineNumber: 'N/A', columnNumber: 'N/A', codeError };
        
    }
}
